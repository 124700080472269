import React from 'react'
import Slider from './Slider'
import About from './About'
import Contact from '../Contact/Contact'
import Product from './Product'
// import WhyChoose from './WhyChoose'

import Amenties from '../Gallery/Gallery'
import Locations from '../Location/Location'
import Internal_Amenties from '../Internal_Amenties/Internal_Amenties'






const Home = () => {
  return (
    <>
      <Slider/>
      <About/>
      <Product/>
      <Amenties/>
      <Locations/>
      <Internal_Amenties/>
      <Contact/>

      




      

    </>
  )
}

export default Home
