import Image1 from '../../../assets/images/yoga.png';
import Image2 from '../../../assets/images/child.png';
import Image3 from '../../../assets/images/lobby.png';
import Image4 from '../../../assets/images/walk.png';
import Image5 from '../../../assets/images/gym.png';








const GalleryArray = [
    {
        id:"1",
        ImgSrc:Image1,
        name:'YOGA RETREAT',
        xs: 6,
        md: 4,
        lg: 3,
        aos:'fade-up'
    },
    {
        id:"2",
        ImgSrc:Image2,
        name:'CHILDREN’S PLAY AREA',
        xs: 6,
        md: 4,
        lg: 3,
        aos:'fade-up'


    },
    {
        id:"3",
        ImgSrc:Image3,
        name:'LOBBY',
        xs: 6,
        md: 6,
        lg: 6,
        aos:'zoom-in'


    },
    {
        id:"4",
        ImgSrc:Image4,
        name:'WALKING AREA',
        xs: 6,
        md: 6,
        lg: 6,
        aos:'fade-down'




    },
    {
        id:"5",
        ImgSrc:Image5,
        name:'ENCLOSED ROOFTOP GYMNASIUM',
        xs: 6,
        md: 6,
        lg: 6,
        aos:'zoom-in'


    },
    
   
    
   
];
export default GalleryArray;

